@import "../../node_modules/react-bootstrap-toggle/dist/bootstrap2-toggle.css";
/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600;700&display=swap');*/
@font-face {
  font-family: 'Open Sans', sans-serif;
  src: url(../assets/fonts/Open_Sans/OpenSans-Light.ttf), url(../assets/fonts/Open_Sans/OpenSans-Italic.ttf), url(../assets/fonts/Open_Sans/OpenSans-Regular.ttf), url(../assets/fonts/Open_Sans/OpenSans-SemiBold.ttf);
}
.space-100 {
  height: 100px;
}
.space {
  height: 10px;
}
.space-50 {
  height: 50px;
}
.web-add-customer {
  display: block;
}
.mobile-add-customer {
  display: none;
}
.all-customers-mobile {
  padding: 10px 0;
}
.all-customers-mobile a {
  color: #111;
}
.all-customers-mobile a:active,
.all-customers-mobile a:focus {
  text-decoration: none;
}
.back-button-header {
  position: relative;
  right: 50px;
  /* border-radius: 50px; */
  padding: 15px 17px;
}
p.text2,
p.text1 {
  color: #fff;
}
.menu-items {
  margin: 7px;
  padding: 30px;
}
.center-items {
  -ms-flex-align: center;
  align-items: center;
}
.inner-menu-item {
  background-color: #091c7c;
  border-radius: 5px;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.inner-menu-item .item-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sub-main-content .application-container2 {
  margin-top: 36px;
}
.application-container2 .form-inline label {
  align-items: left !important;
  justify-content: left !important;
}
@media print {
  .sales_reports,
  .navbar,
  .nav {
    display: none !important;
  }
  .sub-main-content,
  .sub-inv-main-content {
    left: 0 !important;
    width: 100% !important;
  }
  .sidebar,
  .sub-sidebar {
    width: 1px !important;
  }
  .print-not-visible {
    display: none !important;
  }
  #divToPrint {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
  #divToPrint .table {
    width: 100% !important;
  }
}
body {
  background: #ececec !important;
}
.full-width {
  width: 100% !important;
}
.background-white {
  background: #ffffff !important;
}
.bg-active-color {
  background: #a5a5a5;
}
.text-white {
  color: #ffffff;
}
.text-black {
  color: #000000;
}
.text-gray {
  color: #adadad;
}
.text-center {
  text-align: center;
}
.border-radius-50 {
  border-radius: 50px !important;
}
.margin-20 {
  margin: 20px !important;
}
.margin-y-24 {
  margin: 24px 0 !important;
}
.marginR-10 {
  margin-right: 10px;
}
.marginR-15 {
  margin-right: 15px;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-20 {
  padding: 20px;
}
.padding-10-40 {
  padding: 10px 40px !important;
}
.padding-0-17 {
  padding: 0 17px;
}
.center-div {
  margin: 0 auto;
}
.height-500 {
  height: 500px;
}
.amcharts-chart-div a {
  display: none !important;
}
.application-container {
  min-height: 500px !important;
}
.height-0 {
  height: 0 !important;
}
.permissions-container {
  min-height: 500px !important;
  padding: 40px 50px 10px 50px;
}
.update-permissions-container {
  min-height: 500px !important;
  padding: 40px 50px 10px 50px;
}
.chart-empty {
  height: 500px;
  padding-top: 235px;
  text-align: center;
}
.not-visible {
  display: none !important;
}
.custom-toggle .btn-default {
  background-color: #eee !important;
}
#divToPrint {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.tbl-excel {
  width: 100%;
}
.tbl-excel td {
  text-align: center;
  width: 100px;
  font-size: 12px;
}
#add_payment .react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}
#add_payment .react-datepicker-wrapper .react-datepicker__input-container {
  display: block !important;
}
#add_invoice .react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}
#add_invoice .react-datepicker-wrapper .react-datepicker__input-container {
  display: block !important;
}
.add_inventory .react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}
.add_inventory .react-datepicker-wrapper .react-datepicker__input-container {
  display: block !important;
}
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  display: block !important;
}
/* table */
.table {
  width: 100% !important;
  /*padding-right: 20px;*/
}
.table th {
  color: #0c0c0c;
  border: none !important;
}
.table .table-btn {
  background-color: #cdcdcd;
  padding: 5px 30px;
}
.table.dataTable.no-footer {
  border-bottom: none !important;
}
.table.table-bordered {
  color: #000000;
  border-color: #000000;
}
.table.table-bordered th {
  color: #000000;
  border: 1px solid #000000 !important;
  text-align: center;
}
.table.table-bordered td {
  color: #000000;
  border: 1px solid #000000 !important;
  text-align: center;
}
.table.table-bordered td input {
  border: 1px solid #ccc !important;
  border-radius: 2px !important;
  outline: none !important;
}
.table.table-bordered td .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.table td input {
  border: none !important;
  border-bottom: 1px solid #bdb6b6 !important;
  border-radius: 0 !important;
  outline: none !important;
}
.table td .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* datatables */
.paginate_button {
  padding: 2px !important;
}
.view-customer .dataTables_length {
  display: none !important;
}
img.avatar {
  height: 100px;
  margin: 0 auto;
  width: 100px;
}
.nav-box-shadow {
  box-shadow: 1px 1px 1px #cdcdcd;
}
.u-permission-table td:nth-child(2) {
  width: 300px;
}
/*== navbar =====================  */
.header .session-label {
  font-size: 13px;
  color: #091c7c;
}
.header .nav-header-icon {
  height: 30px;
}
/* === anchor styles =======*/
.no-textdecoration {
  text-decoration: none !important;
}
/* === button styles =======*/
.btn-no-style {
  border: none;
  background: transparent;
  cursor: pointer;
}
/* === label styles =====*/
.activeLabel {
  color: #091c7c !important;
  letter-spacing: 1px;
  background-color: #ffffff !important;
}
.font-red {
  color: red;
}
.span {
  font-family: 'Roboto', sans-serif !important;
}
/*==== select styles ======*/
.error-select {
  border: 1px solid #dc3545;
  border-radius: 3px;
}
.sidebar {
  background: #ffffff;
  position: fixed;
  height: 100%;
  padding: 0 !important;
  width: 15%;
  top: 0;
  /*top: 72px;*/
  z-index: 10;
}
.sidebar .sidebar-content {
  color: #091c7c;
}
.sidebar .nav-item {
  display: flex;
}
.sidebar .nav-item:hover {
  background: #e8edff;
  cursor: pointer;
}
.sidebar .logo {
  width: 50px;
  height: 50px;
  background: #cdcdcd;
  float: left;
  border-radius: 50%;
}
.sidebar .nav.flex-column .active {
  background: #e8edff;
}
.sidebar .nav.flex-column li {
  padding: 5px 0;
}
.sidebar .sidebarLabelBlue {
  color: #091c7c !important;
}
.sidebar .sidebarLabel {
  display: inline-flex;
  font-weight: 600;
  padding: 6px;
  color: #4f4f4f;
  width: 100%;
}
.sidebar .sidebarIcons {
  display: inline-block;
  margin: -3px 13px;
  max-width: 22px;
}
.sidebar .sidebarIcons img {
  width: 33px;
}
.sidebar .sidebarLabel:hover {
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.sidebar .nav-item {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2px;
}
.sidebar .nav.flex-column li:first-child {
  margin-top: 0px;
}
li.nav-item div {
  color: white;
}
.main-content {
  width: 85%;
  height: 100px;
  left: 15%;
  position: absolute;
  top: 6px;
  padding: 50px 30px;
}
.sub-sidebar {
  width: 15%;
  position: fixed;
  background: #ebebeb;
  left: 15%;
  padding-left: 10px;
  height: 100%;
  z-index: 9;
  top: 55px;
  overflow-y: auto;
  padding-bottom: 70px;
}
.sub-sidebar .nav-item button {
  width: 100%;
}
.sub-sidebar .nav-item {
  position: relative;
  display: flex;
  justify-content: left;
}
.sub-sidebar .nav-item a {
  color: #4f4f4f;
  background: #dcdcdc;
  width: 100%;
}
.sub-sidebar .nav-item.non-nav:hover {
  background-color: transparent;
}
.sub-sidebar .nav-item a:hover,
.sub-sidebar .nav-item a.active {
  background: white !important;
  cursor: pointer;
}
.sub-sidebar .non-nav > a {
  text-align: center;
}
.sub-sidebar .non-nav-item,
.sub-sidebar .and-text {
  display: block;
  text-align: center;
}
.sub-sidebar .list {
  display: unset;
}
.sub-sidebar .list:first-child {
  margin-top: 20px;
}
.sub-sidebar .list .nav-item.divider {
  width: 100%;
  align-self: center;
  border-bottom: 1px solid #ccc;
}
.sub-sidebar .list .nav-item.divider:hover {
  background-color: transparent;
}
.sub-sidebar .list li {
  padding: 5px 0;
  color: black;
}
.sub-sidebar .list h4 {
  font-size: 26px !important;
  color: black;
  font-weight: 700;
}
.sub-sidebar button {
  background: #f6bf17;
  height: 50px;
  color: black;
  font-size: 13px;
  font-weight: bold;
}
/*.sub-sidebar {
    width: 15%;
    
    position: fixed;
    left: 5%;
    height: 100%;
}
*/
.font-size-14 {
  font-size: 14px;
}
.width-15 {
  width: 15%;
}
.padding-0-15 {
  padding: 0 15px;
}
.padding-0-12 {
  padding: 0 12px;
}
.sub-main-content-ledger {
  width: 74%;
  height: auto;
  right: 0;
  position: absolute;
  background: #ebebeb;
  padding-bottom: 10px;
  padding-left: 10px;
  top: 70px;
  min-height: 90%;
  overflow-y: auto;
}
.sub-main-content,
.sub-inv-main-content {
  width: 71%;
  height: auto;
  right: 0;
  position: absolute;
  background: #ebebeb;
  padding-bottom: 10px;
  padding-left: 10px;
  top: 70px;
  min-height: 90%;
  overflow-y: auto;
}
.sub-main-content .content-header,
.sub-inv-main-content .content-header {
  background: #ffffff;
  padding: 10px 0;
  margin-bottom: 19px;
  border-radius: 3px;
}
.sub-main-content .content-header input,
.sub-inv-main-content .content-header input {
  border-radius: 0;
  padding: 0 0 0 30px;
  box-shadow: none;
  border: none;
  font-family: FontAwesome, sans-serif;
}
.sub-main-content .content-header .application-btn,
.sub-inv-main-content .content-header .application-btn {
  margin: 10px;
  height: 40px;
  width: 200px;
  border-radius: 50px;
}
.sub-main-content .application-container,
.sub-inv-main-content .application-container {
  padding: 10px;
}
.sub-main-content .application-container img.avatar,
.sub-inv-main-content .application-container img.avatar {
  height: 100px;
  width: 100px;
}
.sub-main-content .application-container .personal-info .row,
.sub-inv-main-content .application-container .personal-info .row {
  padding: 15px;
}
.sub-main-content .btn-add-payment,
.sub-inv-main-content .btn-add-payment {
  background: #dda806;
  height: 50px;
  color: #6e5301;
  font-size: 16px;
}
/*
	inventory
*/
.add_inventory {
  min-height: 90% !important;
}
table.add-inventory .react-bootstrap-daterangepicker-container {
  display: block !important;
}
.active-inventory {
  color: #091c7c !important;
  background: white !important;
}
.no-access-div {
  margin-top: 200px;
  text-align: center;
}
.add-inventory td input[type="text"],
.add-inventory td input[type="date"] {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}
.my-inventory .react-bootstrap-daterangepicker-container {
  display: block !important;
}
.my-inventory .react-bootstrap-daterangepicker-container button {
  display: block !important;
  width: 100% !important;
}
.add-inventory td input[type="text"],
.add-inventory td input[type="date"] {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}
.inventory-daterangepicker .react-bootstrap-daterangepicker-container {
  display: block !important;
}
.inventory-daterangepicker .react-bootstrap-daterangepicker-container button {
  display: block !important;
  width: 100% !important;
}
.add_inventory_secondhand .react-autosuggest__input,
.add-customer-secondhand-unit .react-autosuggest__input {
  width: 100%;
  padding: 0.375rem 0.75rem;
}
.add_inventory_secondhand .react-datepicker-wrapper {
  width: 100%;
}
.add_inventory_secondhand .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}
.add_inventory_secondhand .form-control {
  text-transform: uppercase;
}
.add_inventory_secondhand .Select-placeholder {
  text-transform: uppercase;
}
.add_inventory_secondhand .Select-input input {
  text-transform: uppercase;
}
.inventory_secondhand .react-autosuggest__input,
.add-customer-secondhand-unit .react-autosuggest__input {
  border-radius: 2px !important;
  border: 1px solid #ccc !important;
  padding: 0.375rem 0.75rem;
  width: 100%;
  text-transform: uppercase;
}
.inventory_secondhand input,
.add-customer-secondhand-unit input {
  text-transform: uppercase;
}
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 100%;
  height: 36px;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.react-autosuggest__input--focused {
  outline: none;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 33px;
  width: 100%;
  min-width: 160px;
  margin-left: 1px;
  background-color: #FFFFFF;
  border-radius: 0 0 4px 4px;
  z-index: 2;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #fff;
}
/*
	report
*/
.report-option-icons {
  float: right;
}
.report-option-icons a {
  margin-right: 25px;
  font-size: 20px;
}
.report-option-icons-2 a {
  margin-right: 25px;
  font-size: 20px;
}
.create-report-unsold .react-bootstrap-daterangepicker-container {
  display: block !important;
}
.float-right {
  float: right;
}
.login-content .login-main {
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  margin: 10% auto 0;
  padding: 20px;
  width: 320px;
}
.login-content .logo-name {
  color: #3893e0;
}
.login-content .settings-wrap {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
@media screen and (max-width: 320px) {
  .login-content .login-main {
    padding: 10px;
    width: 290px;
  }
}
/* -- reset password */
.reset-pass {
  margin: 0 auto;
  max-width: 300px;
  padding-top: 100px;
}
.reset-pass .page-header {
  margin-bottom: 50px;
}
.reset-pass .page-header h1 {
  text-align: center;
}
.reset-pass .login-link {
  text-align: center;
}
/* password reset requests */
.notifications-table .unread-request {
  background-color: #ccc;
}
/* user info */
.nav-tabs {
  border-bottom: none !important;
}
.nav-tabs li {
  padding: 10px 20px;
}
.nav-tabs li.active {
  background: #ffffff;
  padding: 10px 20px;
  height: 100%;
}
.nav-tabs li a {
  text-decoration: none;
  font-size: 14px;
  color: #0c0c0c;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}
.tab-pane.view-cust {
  padding: 1px;
}
.tab-pane.user-info,
.tab-pane.user-new {
  padding: 20px !important;
}
.modal-body .add-customer-modal {
  padding: 40px 0;
}
/* ACCOUNTING SYSTEM */
.system-type span {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  -webkit-user-select: none;
  user-select: none;
  padding: 5px;
  margin: 0 auto;
  width: 150px;
  transition-duration: 0.4s;
  background: #091c7c;
  color: white;
  border-radius: 13px;
}
.system-type .dropdown {
  background: #fff;
  padding: 10px;
  position: fixed;
  height: 50px;
  top: 85px;
  left: 13px;
  border-radius: 3px;
  border: 1px solid #eee;
  box-shadow: #aaa 0px 2px 3px;
  z-index: 11;
}
.system-type .dropdown a:hover {
  text-decoration: none;
}
.app-version {
  position: fixed;
  bottom: 0;
  color: #091c7c;
  width: 15%;
  padding: 15px !important;
}
.loader {
  border: 3px solid #0069d9;
  border-radius: 50%;
  border-top: 3px solid #f3f3f3;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  position: absolute;
  top: 3px;
  left: -100px;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader.not-visible {
  display: none !important;
}
@media (min-width: 576px) {
  .form-inline .form-control {
    width: 100% !important;
  }
}
.mobile-acc-reports__wrapper {
  display: none;
}
@media only screen and (max-width: 576px) {
  .web-acc-reports__wrapper {
    display: none !important;
  }
  .web-acc-reports__wrapper-ledger {
    display: none !important;
  }
  .mobile-acc-reports__wrapper {
    display: block !important;
    position: fixed;
    top: 10%;
  }
  .tab-acc-dashboard__wrapper {
    display: none;
  }
}
.margin-top-5 {
  margin-top: 5px;
}
.logo__wrapper {
  width: 100%;
  padding-bottom: 30px;
}
.logo__wrapper .system-type {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 18px;
}
.dropdown-selection {
  background: transparent !important;
  color: #091c7c !important;
}
.nav-content__wrapper {
  position: fixed;
  z-index: 1030;
  left: 15%;
  width: 100%;
  margin-top: -17px;
  padding: 34px 8px;
  background: #ebebeb !important;
  height: 77px;
}
.logout__wrapper {
  position: relative;
  top: 7px;
  width: 100%;
  right: 0;
}
.logout__wrapper .logout-icon {
  position: absolute;
  right: -10px;
  top: -11px;
}
.logout__wrapper .logout-label {
  position: absolute;
  right: 30px;
  top: -6px;
}
.logout__wrapper .logout-back button {
  border: none;
  color: #000e52;
  font-weight: 600;
  text-transform: uppercase;
}
.logout__wrapper .logout-back {
  left: -6px;
  top: -2px;
  width: 227px;
  position: absolute;
}
.subheader__name {
  color: black;
  list-style: none;
}
.inv-form__wrapper {
  width: 100%;
  position: relative;
  margin-top: -30px;
}
.inv-form__wrapper .inv-form__col {
  width: 45%;
  margin: 0 17px;
  display: inline-block;
}
.inv-form__div {
  margin-top: 20px;
}
.inv-form__check {
  margin-right: 25px;
  margin-top: 15px;
}
.inv-form__check input {
  margin-right: 5px;
}
.btn-second-primary {
  background: #0a1c7c !important;
  color: white !important;
  padding: 5px !important;
}
.width-100 {
  width: 100%;
}
.padding-y-15 {
  padding: 15px 0;
}
.subheader-label {
  font-weight: 800;
  margin-left: 8px;
}
.inv-report-content__wrapper {
  margin-left: -5px;
  margin-top: 15px;
}
.toast.toast-success {
  background-color: green !important;
}
.toast.toast-error {
  background-color: red !important;
}
.toast.toast-info {
  background-color: blue !important;
}
.toast.toast-warning {
  background-color: yellow !important;
}
.subheader-title {
  font-size: 28px;
  padding: 13px 0;
  font-weight: 600;
}
.label__wrapper {
  width: 100%;
  padding: 0 12px;
}
.customer-ledger__label-wrapper {
  display: inline-block;
  width: 25%;
  padding: 12px 0;
}
.padding-17 {
  padding: 17px;
}
.customer-ledger__content-wrapper {
  padding: 0 33px;
}
.nav-content__wrapper {
  background: #ececec;
  border-bottom: 1px solid #ddd;
}
@media print {
  .sub-main-content__view {
    width: 100% !important;
  }
}
.sub-main-content__view {
  width: 73%;
  height: auto;
  right: 7px;
  position: absolute;
  background: #ebebeb;
  padding-bottom: 10px;
  padding-left: 10px;
  top: 70px;
  min-height: 90%;
}
.sub-main-content__customer {
  width: 73%;
  height: auto;
  right: 7px;
  position: absolute;
  background: #ebebeb;
  padding-bottom: 10px;
  padding-left: 10px;
  top: 70px;
  min-height: 90%;
}
.sub-main-content__create {
  width: 72%;
  height: auto;
  right: 9px;
  position: absolute;
  background: #ebebeb;
  padding-bottom: 10px;
  padding-left: 10px;
  top: 90px;
}
.logout__wrapper .logout-icon {
  right: 15%;
}
.logout__wrapper .logout-label {
  right: 20%;
}
@media print {
  #idNotToPrintLeft0 {
    left: 0;
  }
  #idNotToPrintThisDiv {
    display: none;
  }
}
@media only screen and (max-width: 1080px) {
}
@media only screen and (max-width: 1080px) {
  .main-content {
    padding: 50px 15px;
    top: 0 !important;
  }
  .sub-main-content,
  .sub-inv-main-content {
    width: 74% !important;
  }
  .main-content {
    width: 85%;
    margin: 10px;
  }
  .system-type span {
    padding: 4px 5px;
  }
  .web-page {
    margin: 0 10px;
  }
  .sub-sidebar {
    left: 14% !important;
  }
  .notifications-link {
    right: 38% !important;
  }
}
.tab-page {
  display: none;
}
.web-page {
  display: block;
}
@media only screen and (max-width: 2024px) {
  .sub-main-content__create {
    width: 69%;
  }
  .notifications-link {
    position: absolute;
    right: 37% !important;
  }
  .dropdown .dropdown-content {
    width: 81%;
    margin: 29% 14% 0;
  }
  .sub-main-content-ledger {
    width: 70%;
  }
}
.nav-content__wrapper:after {
  content: "";
  display: table;
  clear: both;
}
@media only screen and (max-width: 900px) {
  .system-type span {
    font-weight: 500;
    font-size: 12px;
    width: 130px;
  }
  .sidebar .nav.flex-column li:first-child {
    text-align: left;
    font-size: 13px;
  }
  .sub-sidebar {
    font-size: 14px;
  }
  .global__header-label {
    font-size: 21px !important;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
  padding-left: 0;
  margin-top: 100px;
}
.dropdown .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown .dropdown-content a:hover {
  background-color: #ddd;
  text-decoration: none;
}
.dropdown .dropdown-content {
  display: block;
}
.password-form-control {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.375rem 0.75rem;
}
.user-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.breadcrumb-inv {
  color: black;
}
.add-new-btn {
  margin: 18px;
}
.add-new-btn span {
  margin: 0 10px;
}
.payment-filter-btn {
  margin: 4px 0;
}
.global__header-label {
  font-weight: 600;
  font-size: 24px;
  padding: 0 10px;
}
.login__wrapper {
  margin-top: 40px;
  width: 100%;
  text-align: center;
}
.login__wrapper .login__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 19px;
  background: white;
}
.login__wrapper .login__content-form {
  width: 29%;
  margin: 55px auto 0;
  padding: 31px;
}
.login__wrapper .login__logo {
  padding: 58px 5px 45px 0;
}
.login__wrapper .forgot-password {
  color: #000e52;
  cursor: pointer;
}
/* forgot password btn */
.es-btn-bg {
  background-color: #0a1c7c !important;
}
.search-class {
  width: 100%;
  outline: none;
}
.btn-new-view {
  background: #00bcc2 !important;
  color: white !important;
  margin: 0 2px;
}
.btn-new-edit {
  background: #0072be !important;
  color: white !important;
  margin: 0 2px;
}
.web-wrapper {
  display: block;
}
.web-wrapper .notifications-link {
  position: absolute;
  right: 30%;
  padding: 5px;
  top: -5px;
}
.web-wrapper .notifications-link a {
  position: relative;
}
.web-wrapper .notifications-link a span {
  position: relative;
  top: -11px;
  left: 9px;
}
.web-wrapper .notifications-link a .fa-bell {
  color: #192b84;
}
.tab-wrapper {
  display: none;
}
.clear-button {
  background: #ececec;
  border: none;
  width: 33px;
  z-index: 1;
  margin: 3px 5px 0 -6px;
}
.clear-button:focus,
button:focus {
  outline: none;
}
.clear-button img {
  width: 100%;
}
.sidebar-logo {
  margin: 0 auto;
  width: 212px;
}
.header-logo {
  width: 168px;
  padding: 10px 12px 19px 14px;
}
.mobile-acc-dashboard__wrapper {
  display: none;
}
.web-acc-dashboard__wrapper {
  display: block;
}
.sidebar .dropdown-content li {
  margin: 10px 0 !important;
  padding: 0 !important;
}
.web-acc-reports__wrapper-ledger .list .nav-item h6 {
  text-align: center;
}
@media only screen and (max-width: 360px) {
  .sub-main-content {
    top: 153px !important;
  }
  .sub-inv-main-content {
    top: 153px !important;
  }
  .tab-acc-dashboard__wrapper {
    display: none !important;
  }
  .sub-sidebar {
    top: 77px !important;
  }
}
/* Dropdown Button */
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980B9;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}
/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}
.margin-bottom-minus-15 {
  margin-bottom: -15px;
}
.accounting-customer-mobile__wrapper {
  display: none;
}
@media only screen and (max-width: 800px) {
  .header .session-label {
    font-size: 12px;
    padding: 30px;
  }
}
.margin-left-3 {
  margin-left: 3px;
}
@media only screen and (max-width: 480px) {
  .mobile-container-installment {
    margin-top: 51% !important;
  }
  .mobile-dropdown__wrapper {
    display: block;
    height: auto !important;
    margin-bottom: 15px;
    background: #ececec;
  }
  .accounting-customer-mobile__wrapper {
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    padding: 0 27px;
    margin-bottom: 0;
    background: #ececec;
    top: 6%;
    z-index: 50;
    margin-left: 5px;
  }
  .accounting-customer-mobile__wrapper .mobile-add-customer button {
    background: #f6bf17;
    width: 100%;
    height: 50px;
    color: black;
    font-size: 13px;
    font-weight: bold;
  }
  .mobile-customer-content-wrapper {
    margin-top: 50% !important;
  }
  .dropdown__select {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
  }
  .sub-main-content {
    height: auto;
    left: 0 !important;
    z-index: 10;
    width: 100% !important;
    position: absolute;
    background: transparent;
    padding-bottom: 3px;
    padding-left: 0;
    min-height: 90%;
  }
  .sub-inv-main-content {
    height: auto;
    left: 0 !important;
    z-index: 10;
    width: 100% !important;
    position: absolute;
    background: transparent;
    padding-bottom: 3px;
    padding-left: 0;
    top: 120px !important;
    min-height: 90%;
  }
  .sub-sidebar {
    left: 0 !important;
    height: 116px !important;
  }
  .logout__wrapper .logout-back {
    left: 266px;
    display: none;
  }
  .mobile-inv-dashboard__wrapper {
    display: block;
    margin-bottom: 10px;
    width: 68% !important;
  }
  .mobile-sub-sidebar {
    width: 100%;
    left: 0;
  }
  .tab-header-logo {
    margin-top: 4px !important;
    width: 92px !important;
  }
  .clear-button {
    width: 30px;
    margin: 5px 2px 0 -6px;
  }
  .sidebar {
    width: 40% !important;
  }
  .web-acc-dashboard__wrapper {
    display: none;
  }
  .header .nav-header-icon {
    height: 21px;
    margin-right: -13px;
  }
  .header .session-label {
    font-size: 12px;
    padding: 5px;
  }
  .tab-welcoming__wrapper {
    right: 13% !important;
    top: 38px !important;
  }
  .tab-logout-logo__wrapper {
    right: 12px !important;
  }
  .login__wrapper .login__content-form {
    width: 100%;
    margin: -9px auto 0;
    padding: 31px;
  }
  .login__wrapper .login__logo {
    padding: 47px 5px 26px 0;
  }
  /* Force table to not be like tables anymore */
  .tbl-mobile {
    width: 100% !important;
    display: block;
    /* Hide table headers (but not display: none;, for accessibility) */
  }
  .tbl-mobile td {
    border-top: none;
  }
  .tbl-mobile thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .tbl-mobile tr {
    border-bottom: #dddddd 2px solid;
  }
  .tbl-mobile td {
    /* Behave  like a "row" */
    border-top: none;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }
  .tbl-mobile td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    white-space: nowrap;
    text-align: left;
    padding: 10px;
    font-weight: bold;
  }
  .tbl-mobile td {
    border-top: none !important;
  }
  .tbl-mobile thead,
  .tbl-mobile tbody,
  .tbl-mobile th,
  .tbl-mobile td,
  .tbl-mobile tr {
    display: block;
  }
  /*
  Label the data
You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
  .tbl-category-brand td:nth-of-type(1):before,
  .tbl-category-branch td:nth-of-type(1):before,
  .tbl-category-model td:nth-of-type(1):before,
  .tbl-category-position td:nth-of-type(1):before {
    content: "No.";
  }
  .tbl-category-brand td:nth-of-type(2):before,
  .tbl-category-branch td:nth-of-type(2):before,
  .tbl-category-model td:nth-of-type(2):before,
  .tbl-users td:nth-of-type(1):before,
  .tbl-category-position td:nth-of-type(2):before {
    content: "Name";
  }
  .tbl-category-brand td:nth-of-type(3):before,
  .tbl-category-branch td:nth-of-type(3):before,
  .tbl-category-model td:nth-of-type(4):before,
  .tbl-inventory td:nth-of-type(6):before,
  .tbl-sold td:nth-of-type(8):before,
  .tbl-outgoing td:nth-of-type(5):before,
  .tbl-duplicate td:nth-of-type(4):before,
  .inventory_reports td:nth-of-type(4):before,
  .sales_reports td:nth-of-type(4):before,
  .no-clearance td:nth-of-type(4):before,
  .tba td:nth-of-type(4):before,
  .with-warranty td:nth-of-type(4):before,
  .total-cost td:nth-of-type(4):before,
  .tbl-users td:nth-of-type(5):before,
  .tbl-category-position td:nth-of-type(3):before,
  .tbl-incoming td:nth-of-type(5):before {
    content: "Action";
  }
  .tbl-category-model td:nth-of-type(3):before,
  .tbl-transfer td:nth-of-type(3):before,
  .tbl-outgoing td:nth-of-type(2):before,
  .tbl-view-report-pdf-4 td:nth-of-type(1):before {
    content: "Brand Name";
  }
  .tbl-inventory td:nth-of-type(1):before,
  .tbl-search-dr td:nth-of-type(1):before,
  .tbl-transfer td:nth-of-type(2):before,
  .tbl-sold td:nth-of-type(3):before,
  .tbl-outgoing td:nth-of-type(1):before,
  .tbl-outgoing td:nth-of-type(2):before,
  .tbl-duplicate td:nth-of-type(1):before,
  .tbl-cash-installments td:nth-of-type(1):before,
  .tbl-create-report td:nth-of-type(1):before,
  .tbl-view-report-pdf td:nth-of-type(1):before,
  .tbl-view-report-pdf-1 td:nth-of-type(4):before,
  .tbl-view-report-pdf-2 td:nth-of-type(1):before,
  .tbl-view-report-pdf-4 td:nth-of-type(2):before,
  .tbl-incoming td:nth-of-type(2):before {
    content: "Model Name";
  }
  .tbl-inventory td:nth-of-type(2):before,
  .tbl-search-dr td:nth-of-type(2):before,
  .tbl-duplicate td:nth-of-type(2):before,
  .tbl-duplicate td:nth-of-type(2):before {
    content: "Chassis No.";
  }
  .tbl-inventory td:nth-of-type(3):before,
  .tbl-search-dr td:nth-of-type(3):before,
  .tbl-sold td:nth-of-type(4):before,
  .tbl-transfer td:nth-of-type(5):before,
  .tbl-outgoing td:nth-of-type(4):before,
  .tbl-outgoing td:nth-of-type(3):before,
  .tbl-create-report td:nth-of-type(2):before,
  .tbl-view-report-pdf td:nth-of-type(2):before,
  .tbl-view-report-pdf-1 td:nth-of-type(5):before,
  .tbl-view-report-pdf-2 td:nth-of-type(2):before,
  .tbl-incoming td:nth-of-type(3):before {
    content: "Engine No.";
  }
  .tbl-inventory td:nth-of-type(4):before,
  .tbl-search-dr td:nth-of-type(4):before,
  .tbl-sold td:nth-of-type(5):before,
  .tbl-outgoing td:nth-of-type(5):before,
  .tbl-duplicate td:nth-of-type(3):before,
  .tbl-users td:nth-of-type(4):before,
  .tbl-create-report td:nth-of-type(3):before,
  .tbl-view-report-pdf td:nth-of-type(3):before,
  .tbl-view-report-pdf-1 td:nth-of-type(6):before,
  .tbl-view-report-pdf-2 td:nth-of-type(3):before {
    content: "Branch";
  }
  .tbl-inventory td:nth-of-type(5):before,
  .tbl-search-dr td:nth-of-type(5):before,
  .tbl-create-report td:nth-of-type(4):before,
  .tbl-view-report-pdf td:nth-of-type(4):before,
  .tbl-view-report-pdf-2 td:nth-of-type(4):before {
    content: "Date Received";
  }
  .tbl-sold td:nth-of-type(1):before,
  .tbl-view-report-pdf-1 td:nth-of-type(2):before {
    content: "Customer Name";
  }
  .tbl-sold td:nth-of-type(2):before,
  .tbl-view-report-pdf-1 td:nth-of-type(3):before {
    content: "Account No.";
  }
  .tbl-sold td:nth-of-type(6):before {
    content: "Term";
  }
  .tbl-view-report-pdf-2 td:nth-of-type(5):before {
    content: "Invoice No.";
  }
  .tbl-view-report-pdf-2 td:nth-of-type(6):before {
    content: "Delivery Receipt No.";
  }
  .tbl-sold td:nth-of-type(7):before,
  .tbl-cash-installments td:nth-of-type(2):before,
  .tbl-view-report-pdf-1 td:nth-of-type(8):before {
    content: "Payment";
  }
  .tbl-transfer td:nth-of-type(1):before {
    content: "";
  }
  .tbl-transfer td:nth-of-type(4):before,
  .tbl-outgoing td:nth-of-type(3):before {
    content: "Color";
  }
  .tbl-outgoing td:nth-of-type(6):before,
  .tbl-outgoing td:nth-of-type(1):before,
  .tbl-incoming td:nth-of-type(1):before {
    content: "Date Transffered";
  }
  .tbl-outgoing td:nth-of-type(4):before,
  .tbl-incoming td:nth-of-type(4):before {
    content: "From";
  }
  .tbl-payment td:nth-of-type(1):before {
    content: "Supplier";
  }
  .tbl-payment td:nth-of-type(2):before {
    content: "Receipt No.";
  }
  .tbl-payment td:nth-of-type(3):before {
    content: "Amount Paid";
  }
  .tbl-payment td:nth-of-type(4):before {
    content: "Date Paid";
  }
  .tbl-view-report-pdf-1 td:nth-of-type(1):before {
    content: "Date Sold";
  }
  .tbl-payment td:nth-of-type(5):before {
    content: "Remarks";
  }
  .tbl-view-report-pdf-1 td:nth-of-type(7):before {
    content: "Term";
  }
  .inventory_reports td:nth-of-type(1):before,
  .sales_reports td:nth-of-type(1):before,
  .no-clearance td:nth-of-type(1):before,
  .tba td:nth-of-type(1):before,
  .with-warranty td:nth-of-type(1):before,
  .total-cost td:nth-of-type(1):before {
    content: "Date";
  }
  .inventory_reports td:nth-of-type(2):before,
  .sales_reports td:nth-of-type(2):before,
  .no-clearance td:nth-of-type(2):before,
  .tba td:nth-of-type(2):before,
  .with-warranty td:nth-of-type(2):before,
  .total-cost td:nth-of-type(2):before {
    content: "Title";
  }
  .inventory_reports td:nth-of-type(3):before,
  .sales_reports td:nth-of-type(3):before,
  .no-clearance td:nth-of-type(3):before,
  .tba td:nth-of-type(3):before,
  .with-warranty td:nth-of-type(3):before,
  .total-cost td:nth-of-type(3):before {
    content: "Create By";
  }
  .tbl-users td:nth-of-type(2):before {
    content: "Username";
  }
  .tbl-users td:nth-of-type(3):before {
    content: "Role";
  }
  .tbl-view-report-pdf-4 td:nth-of-type(3):before {
    content: "Cost";
  }
  .dataTables_empty:nth-of-type(1):before {
    display: none;
  }
  .notifications-link {
    right: 43% !important;
  }
}
.tab-acc-dashboard__wrapper {
  display: none;
}
.tab-acc-dashboard__wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.web-ledger__wrapper {
  display: block;
}
.mobile-ledger__wrapper {
  display: none;
}
.half-width {
  width: 47%;
}
@media only screen and (max-width: 610px) {
  .web-ledger__wrapper {
    display: none;
  }
  .mobile-ledger__wrapper {
    display: block;
  }
  .mobile-ledger__wrapper .subheader-title {
    font-size: 20px;
    text-align: center;
  }
  .mobile-ledger__wrapper .ledger-view-row-contents {
    margin-top: 16px;
  }
  .mobile-ledger__wrapper .ledger-name-truncate {
    width: 60%;
  }
  .mobile-ledger__wrapper .ledger-add-customer-btn {
    margin-top: 11px;
  }
}
.notifications-link-mobile {
  display: none;
}
@media only screen and (max-width: 414px) {
  .notifications-link {
    display: none;
  }
  .notifications-link-mobile {
    display: block;
    position: absolute;
    left: 33%;
    padding: 7px;
  }
  .notifications-link-mobile .fa-bell {
    color: #0a1c7c;
  }
  .nav-content__wrapper {
    padding: 33px 8px 0 8px !important;
  }
}
@media only screen and (max-width: 768px) {
  .web-acc-dashboard__wrapper {
    display: none;
  }
  .sticky {
    top: 92px !important;
    margin-right: -15px;
  }
  .sticky-division {
    position: -webkit-sticky;
    position: sticky;
    top: 58px;
    background: #ececec;
    padding: 6px 11px 15px;
    z-index: 99;
    margin-right: -15px;
  }
  .sub-main-content-ledger {
    width: 100%;
    top: 22%;
    padding: 0;
  }
  .sub-main-content__view {
    width: auto;
    height: auto;
    position: absolute;
    padding: 0;
    top: 205px;
  }
  .tab-add-new-customer {
    width: 25% !important;
    right: 22px;
    margin-top: 17px;
    position: absolute !important;
  }
  .mobile-acc-dashboard__wrapper {
    display: block;
    margin-bottom: 10px;
    background: #ececec;
  }
  .mobile-list-dashboard__wrapper {
    margin-bottom: 16px;
  }
  .inner-menu-item {
    position: relative;
    background-color: #091c7c;
    border-radius: 5px;
    height: 100px;
    margin: auto;
    width: 233px;
    padding-top: 0;
  }
  .inner-menu-item .label-dashboard {
    position: absolute;
    right: 84px;
    top: 30%;
    font-size: 16px;
  }
  .inner-menu-item .sub-label-dashboard {
    position: absolute;
    right: 84px;
    top: 51%;
    font-size: 16px;
  }
  .inner-menu-item .icon-dashboard {
    position: absolute;
    left: 12px;
    top: 29%;
    height: 32px;
  }
  .tab-h4 {
    font-weight: 600;
    padding: 10px 20px;
    margin-top: 7px;
  }
  .tab-subheader-label .list li {
    width: 25% !important;
  }
  .tab-subheader-label {
    float: left;
    padding: 10px;
    width: 100%;
    background: #ebebeb;
  }
  .tab-acc-dashboard__wrapper {
    top: 69px !important;
    display: inline-block;
  }
  .web-add-customer {
    display: none !important;
  }
  .mobile-add-customer {
    display: block !important;
    margin-bottom: 8px;
  }
  .header-logo {
    width: 106px;
  }
  .main-content {
    width: 83%;
    top: 3% !important;
  }
  .inv-main-content {
    top: 4% !important;
  }
  .logout__wrapper .logout-back {
    top: -11px;
  }
  .main-content {
    left: 17%;
  }
  .tab-header-logo {
    margin-top: -15px;
    width: 113px;
  }
  .web-wrapper {
    display: none !important;
  }
  .web-wrapper .nav-item .logout-label {
    display: none !important;
  }
  .sidebar-logo {
    width: 107px;
    margin-left: 40px;
  }
  .x-button-sidebar {
    position: absolute;
    left: 0;
    background: transparent;
  }
  .tab-wrapper {
    display: inline-block;
    display: -webkit-inline-box;
    width: 33px;
  }
  .tab-wrapper .notifications-link {
    padding: 11px;
    position: absolute;
    right: 29% !important;
  }
  .tab-wrapper .notifications-link .fa-bell {
    color: #0a1c7c;
  }
  .tab-welcoming__wrapper {
    right: 43px;
    position: absolute;
    top: 16px;
  }
  .tab-logout-logo__wrapper {
    position: absolute;
    right: 0;
  }
  .logo__wrapper img {
    padding: 3px;
    margin: 6px;
  }
  .nav-content__wrapper {
    width: 82%;
    left: 19%;
  }
  .tab-page {
    display: block;
  }
  .web-page {
    display: none;
  }
  .sub-sidebar {
    left: 0 !important;
    top: 57px;
    height: 175px;
    width: 100% !important;
    z-index: 999;
    padding: 0 20px;
  }
  .sub-sidebar .list li {
    padding: 5px;
    list-style: none;
  }
  .sub-sidebar .list .nav-item.divider {
    width: 97% !important;
  }
  .inv-sub-sidebar {
    top: 60px !important;
  }
  .old-record-sub-sidebar {
    height: 140px !important;
  }
  .web-acc-dashboard__wrapper {
    height: 245px !important;
  }
  .web-acc-reports__wrapper {
    height: 278px !important;
  }
  .web-acc-reports__wrapper-ledger {
    height: auto !important;
  }
  .system-type span {
    font-size: 13px;
    padding: 4px 8px;
  }
  .sidebar {
    width: 21%;
    z-index: 9999;
  }
  .sidebar li.nav-item.active {
    width: 100%;
  }
  .sidebar .sidebarLabel {
    text-align: center;
    padding: 8px 0;
    width: 100%;
    font-size: 14px;
  }
  .sidebar .sidebarLabel span {
    margin: auto 0;
    height: 20px;
    padding: 0;
  }
  .sub-main-content {
    width: 100% !important;
    height: auto;
    padding: 0;
    right: 0;
    top: 175px;
  }
  .sub-inv-main-content {
    width: 100% !important;
    height: auto;
    padding: 0;
    right: 0;
    top: 175px;
    margin-top: 2%;
    left: 0 !important;
  }
  .accounting-customer-content {
    top: 258px !important;
  }
  .acc-report-content {
    top: 295px !important;
  }
  .nav-item {
    width: auto;
    float: left;
  }
  .sub-main-content__create {
    width: 100%;
    height: auto;
    right: 0;
    left: 0;
    position: absolute;
    background: #ebebeb;
    padding: 20px !important;
    top: 62px;
    min-height: 90%;
  }
  .tab-show-wrapper {
    display: block !important;
  }
  .tab-hide-wrapper {
    display: none !important;
  }
  .sub-main-content {
    margin-top: 20%;
  }
  .sub-main-content__create .container-fluid {
    margin-top: 33%;
  }
}
.tab-show-wrapper {
  display: none;
}
.tab-hide-wrapper {
  display: block;
}
.daterangepicker[style*="top: 340px; left: 40px; right: auto;"],
.daterangepicker[style*="top: 371px; left: 60px; right: auto;"],
.daterangepicker[style*="top: 406px; left: 40px; right: auto;"],
.daterangepicker[style*="top: 307px; left: 40px; right: auto;"],
.daterangepicker[style*="top: 386px; left: 60px; right: auto;"],
.daterangepicker[style*="top: 338px; left: 60px; right: auto;"] {
  z-index: 99999;
}
.clear-data-mobile {
  display: none !important;
}
.tbl-bir {
  overflow-y: auto;
}
.scroll-y {
  overflow-y: scroll;
}
.container-fluid::-webkit-scrollbar-track {
  background-color: #fff;
}
.container-fluid::-webkit-scrollbar {
  width: 5px;
}
.container-fluid::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaaaa;
}
.overwrite-height-by-120 {
  height: 117px !important;
}
.ledger-add-customer-btn {
  float: right;
  background: #f6bf17;
  color: black;
  font-size: 15px;
  border-radius: 3px;
  border: 1px solid #b3b3b3;
  padding: 4px;
  font-weight: 600;
}
.ledger-name-truncate {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
  background: #ececec;
  padding: 6px 11px 15px;
  z-index: 99;
}
.overflow-y-auto {
  overflow-y: auto;
}
.margin-right-5 {
  margin-right: 5px;
}
